import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { Auth, signOut, User, EmailAuthProvider, onAuthStateChanged, getAuth } from '@angular/fire/auth';
import  * as firebaseui from 'firebaseui'
import { firebaseApp } from 'src/app/app.module';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'kc-admin-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  public user: User | null | undefined = undefined;

  showLoading: boolean = true;

  constructor(private auth: Auth) {

  }

  ngOnInit(): void { 
    const auth = getAuth(firebaseApp);
    onAuthStateChanged(auth, (user: User | null) => {
      this.user = user
      if (!user) setTimeout(() => {this.showFirebaseUI()}, 250)
    });
  }

  async showFirebaseUI() {
    const _this = this

    const ui = firebaseui.auth.AuthUI.getInstance() ||  new firebaseui.auth.AuthUI(getAuth(firebaseApp));
    ui.start('#firebaseui-auth-container', {
      callbacks: {
        signInSuccessWithAuthResult: function(authResult, redirectUrl) {
          // User successfully signed in.
          // Return type determines whether we continue the redirect automatically
          // or whether we leave that to developer to handle.
          return true;
        },
        uiShown: function() {
          // The widget is rendered.
          // Hide the loader.
          _this.showLoading = false;
        }
      },
      signInSuccessUrl: environment.baseUrl,
      signInOptions: [
        {
          provider: EmailAuthProvider.PROVIDER_ID,
          // signInMethod: EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
          signInMethod: EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
          forceSameDevice: true,
        },
      ],
    });  
  }

  async logout() {
    return await signOut(this.auth);
  }

  ngOnDestroy(): void {

  }

}

import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { AdminUserService, SnackBarService } from 'src/app/services';
import { environment } from 'src/environments/environment';
import { UserInfo } from 'firebase/auth';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent {
  admins: UserInfo[] = [];
  public err: HttpErrorResponse | undefined;
  public isErr: boolean | undefined ;
  staticBaseUrl: string =  environment.staticBaseUrl;

  constructor(
    private adminUserService: AdminUserService,
    private snackBarService: SnackBarService,
  ) { }

  ngOnInit() {
    this.adminUserService.findAdmins().subscribe(res => {
      this.admins = res.data.admins;
      this.isErr = false;
    },
    (err: any) => {
      this.snackBarService.presentToast(err.error.message, 'danger');
      this.err = err;
      this.isErr = true;
    })
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminUserService {

  constructor(private http: HttpClient) { }

  findAdmins() {
    return this.http.get<any>(`${environment.apiBaseUrl}/admin/super-admin/user`);
  }

}

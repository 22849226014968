import { Component } from '@angular/core';

@Component({
  selector: 'kc-admin-loan',
  templateUrl: './loan.component.html',
  styleUrls: ['./loan.component.scss']
})
export class LoanComponent {

}

import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FirebaseApp, initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
// Angular Material
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
// firebase stuff
import { provideAnalytics, getAnalytics, ScreenTrackingService, UserTrackingService, AnalyticsModule } from '@angular/fire/analytics';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { provideDatabase,getDatabase } from '@angular/fire/database';
import { provideFirestore,getFirestore } from '@angular/fire/firestore';
import { provideFunctions,getFunctions } from '@angular/fire/functions';
import { provideMessaging,getMessaging } from '@angular/fire/messaging';
import { providePerformance,getPerformance } from '@angular/fire/performance';
import { provideRemoteConfig,getRemoteConfig } from '@angular/fire/remote-config';
import { provideStorage,getStorage } from '@angular/fire/storage';
// Pages
import { LoginComponent } from './pages/login/login.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AdminComponent } from './pages/admin/admin.component';
import { AdminViewComponent } from './pages/admin/view/view.component';
import { AccountComponent } from './pages/account/account.component';
import { AccountViewComponent } from './pages/account/view/view.component';
import { AllowanceComponent } from './pages/allowance/allowance.component';
import { AllowanceViewComponent } from './pages/allowance/view/view.component';
import { BusinessComponent } from './pages/business/business.component';
import { BusinessViewComponent } from './pages/business/view/view.component';
import { CountryComponent } from './pages/country/country.component';
import { CountryViewComponent } from './pages/country/view/view.component';
import { FamilyComponent } from './pages/family/family.component';
import { FamilyViewComponent } from './pages/family/view/view.component';
import { GoalComponent } from './pages/goal/goal.component';
import { GoalViewComponent } from './pages/goal/view/view.component';
import { InvestmentComponent } from './pages/investment/investment.component';
import { InvestmentViewComponent } from './pages/investment/view/view.component';
import { KiddyComponent } from './pages/kiddy/kiddy.component';
import { KiddyViewComponent } from './pages/kiddy/view/view.component';
import { LoanComponent } from './pages/loan/loan.component';
import { LoanViewComponent } from './pages/loan/view/view.component';
import { PaymentCardComponent } from './pages/payment-card/payment-card.component';
import { PaymentCardViewComponent } from './pages/payment-card/view/view.component';
import { TaskComponent } from './pages/task/task.component';
import { TaskViewComponent } from './pages/task/view/view.component';
import { TransactionComponent } from './pages/transaction/transaction.component';
import { TransactionViewComponent } from './pages/transaction/view/view.component';
import { StockComponent } from './pages/stock/stock.component';
import { StockViewComponent } from './pages/stock/view/view.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ProfileViewComponent } from './pages/profile/view/view.component';
import { UserComponent } from './pages/user/user.component';
import { UserViewComponent } from './pages/user/view/view.component';
// services
import { AuthService } from './services';

export let firebaseApp: FirebaseApp;

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NotFoundComponent,
    AccountComponent,
    AccountViewComponent,
    AdminComponent,
    AdminViewComponent,
    AllowanceComponent,
    AllowanceViewComponent,
    BusinessComponent,
    BusinessViewComponent,
    CountryComponent,
    CountryViewComponent,
    FamilyComponent,
    FamilyViewComponent,
    GoalComponent,
    GoalViewComponent,
    InvestmentComponent,
    InvestmentViewComponent,
    KiddyComponent,
    KiddyViewComponent,
    LoanComponent,
    LoanViewComponent,
    PaymentCardComponent,
    PaymentCardViewComponent,
    TaskComponent,
    TaskViewComponent,
    TransactionComponent,
    TransactionViewComponent,
    StockComponent,
    StockViewComponent,
    ProfileComponent,
    ProfileViewComponent,
    UserComponent,
    UserViewComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSidenavModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatExpansionModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    MatSnackBarModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    AnalyticsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    provideFirebaseApp(() => {
      firebaseApp = initializeApp(environment.firebase)
      return firebaseApp
    }),
    provideAuth(() => getAuth(firebaseApp)),
    provideDatabase(() => getDatabase(firebaseApp)),
    provideFirestore(() => getFirestore(firebaseApp)),
    provideFunctions(() => getFunctions(firebaseApp)),
    provideMessaging(() => getMessaging(firebaseApp)),
    providePerformance(() => getPerformance(firebaseApp)),
    provideRemoteConfig(() => getRemoteConfig(firebaseApp)),
    provideStorage(() => getStorage(firebaseApp)),
    provideAnalytics(() => getAnalytics(firebaseApp)),
    ScreenTrackingService,
    UserTrackingService,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [
        AuthService, 
      ],
      useFactory: (
        authService: AuthService,
      ) => async () => {
        await authService.setUp()
      },
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

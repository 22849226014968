import { Component } from '@angular/core';

@Component({
  selector: 'kc-admin-family-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class FamilyViewComponent {

}

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { finalize, Observable, tap } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  token: string | null | undefined;

  constructor(
    private authService: AuthService, 
    private router: Router,
  ) {
    this.authService._$token.subscribe(token => {
      this.token = token;
    });
  } 

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {  
    // If there is no token, proceed
    if (!this.token) return next.handle(request);

    // These API requests don't need a token
    if (request.method === 'GET' && request.url.includes('/cdn-cgi/trace')) {
      return next.handle(request);
    }
    if (request.method === 'GET' && request.url.includes('/api/categories')) {
      return next.handle(request);
    }
    if (request.method === 'GET' && request.url.includes('/api/countries')) {
      return next.handle(request);
    }
    if (request.method === 'GET' && request.url.includes('/api/stocks')) {
      return next.handle(request);
    }

    // The rest need a token set
    const authRequest = request.clone({
      setHeaders: {Authorization: this.token}
    });

    return next.handle(authRequest)
      .pipe(
        tap({
          // Succeeds when there is a response; ignore other events
          next: (event: any) => {
            // succeeded if event instanceof HttpResponse
          },
          // Operation failed; error is an HttpErrorResponse
          error: async (error: HttpErrorResponse) => {
            if (error.status === 401) {
              await this.authService.logout();
              // redirect user to the logout page
              this.router.navigateByUrl('/')
            }
          }
        }),
        // Log when response observable either completes or errors
        finalize(() => {

        })
      );
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserInterface, UserStatusEnum } from '@kiddy-cash/common';
import { SnackBarService } from 'src/app/services';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent {
  app_users: UserInterface[] = [];
  UserStatusEnumValues = Object.values(UserStatusEnum);
  staticBaseUrl: string =  environment.staticBaseUrl;
  showLoading: boolean = false;

  userQueryForm: FormGroup = this.formBuilder.group({
    phone: '',
    email: ['', Validators.email],
    status: '',
  })

  constructor(
    private userService: UserService,
    private snackBarService: SnackBarService,
    private formBuilder: FormBuilder,
  ) {

   }

  ngOnInit() {

  }

  submitQuery(formData: any){
    const data = structuredClone(formData)
    Object.keys(data).forEach((key) => (data[key] === null || data[key] === '') && delete data[key]);
    this.queryForUsers(data)
  }

  queryForUsers(data: any) {
    this.showLoading = true;
    this.app_users = []

    this.userService.findUsers(data).subscribe(res => {
      this.app_users = res.data.app_users;
      this.showLoading = false;
    },
    (err: any) => {
      this.showLoading = false;
      this.snackBarService.presentToast(err.error.message, 'danger');
    })
  }

}

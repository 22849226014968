<div class="container" *ngIf="user === undefined">
    <div class="center">
        <div *ngIf="showLoading">
            <mat-spinner></mat-spinner>
        </div>        
    </div>
</div>

<div class="container" *ngIf="user === null">
    <div class="center">
        <div id="firebaseui-auth-container"></div>
        <div *ngIf="showLoading">
            <mat-spinner></mat-spinner>
        </div>        
    </div>
</div>

<div *ngIf="user">
    <div class="center">
        <button (click)="logout()">Log out</button>
    </div>
</div>

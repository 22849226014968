import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { User } from 'firebase/auth';
import { AuthService } from 'src/app/services';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return new Promise((resolve) => {
    authService._$user.subscribe((user: User | null) => {
      if(user){
        // allow the route
        resolve(true);
      } else {
        // Do not allow the route
        resolve(false);
        router.navigateByUrl('/')
      }
    });
  });
};

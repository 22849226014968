<mat-toolbar color="primary" *ngIf="user">
  <button mat-icon-button (click)="sidenav.toggle()" matTooltip="Menu">
      <mat-icon>menu</mat-icon>
  </button>
  <span routerLink="/">Admin | Kiddy Cash</span>
  <span class="toolbar-item-spacer"></span>
  <button mat-icon-button matTooltip="Notifications" routerLink="/notifications">
      <mat-icon>notifications</mat-icon>
  </button>
</mat-toolbar>

<mat-sidenav-container class="app-container">
  <mat-sidenav #sidenav mode="side" [class.mat-elevation-z4]="true" class="app-sidenav">
      <mat-nav-list>
        <mat-list-item routerLink="/admin">
            <mat-icon matListItemIcon>dashboard</mat-icon>
            <div matListItemTitle> Admins </div>
        </mat-list-item>
        <mat-list-item routerLink="/allowance">
            <mat-icon matListItemIcon>dashboard</mat-icon>
            <div matListItemTitle> Allowances </div>
        </mat-list-item>
        <mat-list-item routerLink="/business">
            <mat-icon matListItemIcon>dashboard</mat-icon>
            <div matListItemTitle> Businesses </div>
        </mat-list-item>
        <mat-list-item routerLink="/country">
            <mat-icon matListItemIcon>dashboard</mat-icon>
            <div matListItemTitle> Countries </div>
        </mat-list-item>
        <mat-list-item routerLink="/family">
            <mat-icon matListItemIcon>dashboard</mat-icon>
            <div matListItemTitle> Families </div>
        </mat-list-item>
        <mat-list-item routerLink="/goal">
            <mat-icon matListItemIcon>dashboard</mat-icon>
            <div matListItemTitle> Goals </div>
        </mat-list-item>
        <mat-list-item routerLink="/investment">
            <mat-icon matListItemIcon>dashboard</mat-icon>
            <div matListItemTitle> Investments </div>
        </mat-list-item>
        <mat-list-item routerLink="/kiddy">
            <mat-icon matListItemIcon>dashboard</mat-icon>
            <div matListItemTitle> Kiddies </div>
        </mat-list-item>
        <mat-list-item routerLink="/loan">
            <mat-icon matListItemIcon>dashboard</mat-icon>
            <div matListItemTitle> Loans </div>
        </mat-list-item>
        <mat-list-item routerLink="/payment-card">
            <mat-icon matListItemIcon>dashboard</mat-icon>
            <div matListItemTitle> Payment Cards </div>
        </mat-list-item>
        <mat-list-item routerLink="/profile">
            <mat-icon matListItemIcon>dashboard</mat-icon>
            <div matListItemTitle> Profiles </div>
        </mat-list-item>
        <mat-list-item routerLink="/stock">
            <mat-icon matListItemIcon>dashboard</mat-icon>
            <div matListItemTitle> Stocks </div>
        </mat-list-item>
        <mat-list-item routerLink="/task">
            <mat-icon matListItemIcon>dashboard</mat-icon>
            <div matListItemTitle> Tasks </div>
        </mat-list-item>
        <mat-list-item routerLink="/transaction">
            <mat-icon matListItemIcon>dashboard</mat-icon>
            <div matListItemTitle> Transactions </div>
        </mat-list-item>
        <mat-list-item routerLink="/user">
            <mat-icon matListItemIcon>dashboard</mat-icon>
            <div matListItemTitle> Users </div>
        </mat-list-item>
      </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
      <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
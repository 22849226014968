import { Component } from '@angular/core';

@Component({
  selector: 'kc-admin-kiddy-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class KiddyViewComponent {

}

import { Component } from '@angular/core';

@Component({
  selector: 'kc-admin-investment',
  templateUrl: './investment.component.html',
  styleUrls: ['./investment.component.scss']
})
export class InvestmentComponent {

}

<form class="query-form" [formGroup]="userQueryForm" (ngSubmit)="submitQuery(userQueryForm.value)">
    <mat-form-field>
        <mat-label>Email</mat-label>
        <input formControlName="email" matInput>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Phone</mat-label>
        <input formControlName="phone" matInput>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select formControlName="status" >
            <mat-option *ngFor="let status of UserStatusEnumValues" [value]="status">{{ status }}</mat-option>
        </mat-select>
    </mat-form-field>

    <br>

    <button mat-raised-button color="accent">Query</button>
</form>

<div class="center" *ngIf="showLoading">
    <div>
        <mat-spinner></mat-spinner>
    </div>        
</div>

<mat-list *ngFor="let app_user of app_users">
    <mat-list-item>
        {{ app_user.id }}
    </mat-list-item>
    <mat-list-item>
        {{ app_user.email }}
    </mat-list-item>
    <mat-list-item>
        {{ app_user.phone }}
    </mat-list-item>
    <mat-list-item>
        {{ app_user.status }}
    </mat-list-item>
</mat-list>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserInterface } from '@kiddy-cash/common';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  findUsers(data: any) {
    return this.http.get<any>(`${environment.apiBaseUrl}/admin/user`, { params: data });
  }


}

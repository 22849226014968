import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';
import { AdminComponent } from './pages/admin/admin.component';
import { AdminViewComponent } from './pages/admin/view/view.component';
import { AllowanceComponent } from './pages/allowance/allowance.component';
import { AllowanceViewComponent } from './pages/allowance/view/view.component';
import { BusinessComponent } from './pages/business/business.component';
import { BusinessViewComponent } from './pages/business/view/view.component';
import { CountryComponent } from './pages/country/country.component';
import { CountryViewComponent } from './pages/country/view/view.component';
import { FamilyComponent } from './pages/family/family.component';
import { FamilyViewComponent } from './pages/family/view/view.component';
import { GoalComponent } from './pages/goal/goal.component';
import { GoalViewComponent } from './pages/goal/view/view.component';
import { InvestmentComponent } from './pages/investment/investment.component';
import { InvestmentViewComponent } from './pages/investment/view/view.component';
import { KiddyComponent } from './pages/kiddy/kiddy.component';
import { KiddyViewComponent } from './pages/kiddy/view/view.component';
import { LoanComponent } from './pages/loan/loan.component';
import { LoanViewComponent } from './pages/loan/view/view.component';
import { LoginComponent } from './pages/login/login.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PaymentCardComponent } from './pages/payment-card/payment-card.component';
import { PaymentCardViewComponent } from './pages/payment-card/view/view.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ProfileViewComponent } from './pages/profile/view/view.component';
import { StockComponent } from './pages/stock/stock.component';
import { StockViewComponent } from './pages/stock/view/view.component';
import { TaskComponent } from './pages/task/task.component';
import { TaskViewComponent } from './pages/task/view/view.component';
import { TransactionComponent } from './pages/transaction/transaction.component';
import { TransactionViewComponent } from './pages/transaction/view/view.component';
import { UserComponent } from './pages/user/user.component';
import { UserViewComponent } from './pages/user/view/view.component';

const routes: Routes = [
  { 
    path: '', 
    component: LoginComponent,
  },
  {
    path:'admin', 
    canActivate: [authGuard],
    component: AdminComponent,
    children: [
      {
        path:':admin_id', 
        canActivate: [authGuard],
        component: AdminViewComponent,
      },
    ]
  },
  {
    path:'allowance', 
    canActivate: [authGuard],
    component: AllowanceComponent,
    children: [
      {
        path:':allowance_id', 
        canActivate: [authGuard],
        component: AllowanceViewComponent,
      },
    ]
  },
  {
    path:'business', 
    canActivate: [authGuard],
    component: BusinessComponent,
    children: [
      {
        path:':business_id', 
        canActivate: [authGuard],
        component: BusinessViewComponent,
      },
    ]
  },
  {
    path:'country', 
    canActivate: [authGuard],
    component: CountryComponent,
    children: [
      {
        path:':country_id', 
        canActivate: [authGuard],
        component: CountryViewComponent,
      },
    ]
  },
  {
    path:'family', 
    canActivate: [authGuard],
    component: FamilyComponent,
    children: [
      {
        path:':family_id', 
        canActivate: [authGuard],
        component: FamilyViewComponent,
      },
    ]
  },
  {
    path:'goal', 
    canActivate: [authGuard],
    component: GoalComponent,
    children: [
      {
        path:':goal_id', 
        canActivate: [authGuard],
        component: GoalViewComponent,
      },
    ]
  },
  {
    path:'investment', 
    canActivate: [authGuard],
    component: InvestmentComponent,
    children: [
      {
        path:':investment_id', 
        canActivate: [authGuard],
        component: InvestmentViewComponent,
      },
    ]
  },
  {
    path:'kiddy', 
    canActivate: [authGuard],
    component: KiddyComponent,
    children: [
      {
        path:':kiddy_id', 
        canActivate: [authGuard],
        component: KiddyViewComponent,
      },
    ]
  },
  {
    path:'loan', 
    canActivate: [authGuard],
    component: LoanComponent,
    children: [
      {
        path:':loan_id', 
        canActivate: [authGuard],
        component: LoanViewComponent,
      },
    ]
  },
  {
    path:'payment-card', 
    canActivate: [authGuard],
    component: PaymentCardComponent,
    children: [
      {
        path:':payment_card_id', 
        canActivate: [authGuard],
        component: PaymentCardViewComponent,
      },
    ]
  },
  {
    path:'profile', 
    canActivate: [authGuard],
    component: ProfileComponent,
    children: [
      {
        path:':profile_id', 
        canActivate: [authGuard],
        component: ProfileViewComponent,
      },
    ]
  },
  {
    path:'stock', 
    canActivate: [authGuard],
    component: StockComponent,
    children: [
      {
        path:':stock_id', 
        canActivate: [authGuard],
        component: StockViewComponent,
      },
    ]
  },
  {
    path:'task', 
    canActivate: [authGuard],
    component: TaskComponent,
    children: [
      {
        path:':task_id', 
        canActivate: [authGuard],
        component: TaskViewComponent,
      },
    ]
  },
  {
    path:'transaction', 
    canActivate: [authGuard],
    component: TransactionComponent,
    children: [
      {
        path:':transaction_id', 
        canActivate: [authGuard],
        component: TransactionViewComponent,
      },
    ]
  },
  {
    path:'user', 
    canActivate: [authGuard],
    component: UserComponent,
    children: [
      {
        path:':user_id', 
        canActivate: [authGuard],
        component: UserViewComponent,
      },
    ]
  },
  { 
    path: '**', 
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

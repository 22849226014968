import { Component } from '@angular/core';

@Component({
  selector: 'kc-admin-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss']
})
export class TransactionComponent {

}

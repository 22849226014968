import { Component } from '@angular/core';

@Component({
  selector: 'kc-admin-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss']
})
export class CountryComponent {

}

<mat-list *ngFor="let admin of admins">
    <mat-list-item>
        {{ admin.uid }}
    </mat-list-item>
    <mat-list-item>
        {{ admin.email }}
    </mat-list-item>
    <mat-list-item>
        {{ admin.phoneNumber }}
    </mat-list-item>
</mat-list>
import { Injectable } from '@angular/core';
import { getAuth, onAuthStateChanged, onIdTokenChanged, signOut, User } from '@angular/fire/auth';
import { BehaviorSubject } from 'rxjs';
import { firebaseApp } from '../app.module';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public _$user = new BehaviorSubject<User | null>(null);
  public _$token = new BehaviorSubject<string | null>(null);

  constructor() { }

  async setUp(){
    const auth = getAuth(firebaseApp);

    onAuthStateChanged(auth, async (user: User | null) => {
      this.setUser(user);
      if(user) {
        const token = await user.getIdToken();
        this.setToken(token);
      }
    });

    onIdTokenChanged(auth, async (user: User | null) => {
      this.setUser(user);
      if(user) {
        const token = await user.getIdToken();
        this.setToken(token);
      }
    });
  }

  setUser(user: User | null) {
    this._$user.next(user);
  }

  setToken(token: string) {
    this._$token.next(token);
  }

  async logout() {
    const auth = getAuth(firebaseApp);

    return await signOut(auth);
  }

}

import { Component } from '@angular/core';
import { User } from 'firebase/auth';
import { AuthService } from './services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  user: User | null = null;

  constructor(private authService: AuthService) {}

  async ngOnInit() {
    this.authService._$user.subscribe((user: User | null) => {
      this.user = user;
    });
  }

}

import { Component } from '@angular/core';

@Component({
  selector: 'kc-admin-goal',
  templateUrl: './goal.component.html',
  styleUrls: ['./goal.component.scss']
})
export class GoalComponent {

}

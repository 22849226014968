import { Component } from '@angular/core';

@Component({
  selector: 'kc-admin-family',
  templateUrl: './family.component.html',
  styleUrls: ['./family.component.scss']
})
export class FamilyComponent {

}
